import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import useConfig from "../../hooks";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import "date-fns";
import frLocale from "date-fns/locale/fr";
import deLocale from "date-fns/locale/de";
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-US";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import ampliusCuisine from "../../assets/AMPLIUS-CUISINE.png";
import ampliusFast from "../../assets/AMPLIUS-FAST.png";
import { FeedbackMessage } from "../../components/FeedbackMessage";
import { scrollToTopHandler } from "../util/scrollToTopHandler";
import { RadioWithImage } from "../../components/RadioWithImage";
import { saveToCookie } from "../util/cookiesHelper";

const localeMap = {
  en: enLocale,
  fr: frLocale,
  de: deLocale,
  es: esLocale,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  radioGroup: {
    flexDirection: "row",
    margin: 0,
  },
  radioButton: {
    marginLeft: -theme.spacing(1),
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },

  input: {
    margin: theme.spacing(1),
    width: "100%",
    "& .MuiFormLabel-root": {
      fontStyle: "italic",
      fontWeight: "bold",
    },
  },
  multiline: {
    width: "100%",
  },
}));

function VisitStep({ stepState, onSubmit, postState, onGoBack }) {
  const [formState, setFormState] = useState({
    step: stepState.step,
    talk: stepState.talk,
    talkDetail: stepState.talkDetail,
    mediaTV: stepState.mediaTV,
    mediaDVD: stepState.mediaDVD,
    therapyType: stepState.therapyType,
    therapyPlanning: stepState.therapyPlanning,
    arrivalDate: stepState.arrivalDate,
    departureDate: stepState.departureDate,
    expectedTimeOfArrival: stepState.expectedTimeOfArrival,
    travellingByCar: stepState.travellingByCar,
    shuttleRequested: stepState.shuttleRequested,
  });

  const imagesUrl = useConfig().imagesUrl;
  const classes = useStyles();
  const { t, i18n } = useTranslation(["translation", "common"]);
  const image1Url = `${imagesUrl}step2-1-1.png`;
  const image2Url = `${imagesUrl}step2-1-2.png`;

  const [toggleError, setToggleError] = useState(false);
  const [showErrors, setShowErrors] = useState({
    arrivalDate: false,
    departureDate: false,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validateFormHandler(formState)) {
      return;
    }
    onSubmit(formState);
  };

  const validateFormHandler = (formState) => {
    let error = { ...showErrors };
    let validationPassed = true;

    const validateField = (key) => {
      if (!formState[key]) {
        error[key] = true;
        validationPassed = false;
      } else {
        error[key] = false;
      }
    };

    ["arrivalDate", "departureDate"].map((key) => validateField(key));

    setShowErrors((state) => ({
      ...state,
      ...error,
    }));
    !validationPassed ? setToggleError(true) : setToggleError(false);
    return validationPassed;
  };

  const handleRadioChange = (state) => {
    setFormState({
      ...formState,
      therapyType: state.target.value,
    });
    saveToCookie(formState, stepState.step);
  };

  const stepBackHandler = (event) => {
    event.preventDefault();

    onGoBack(formState);
  };

  return (
    <div className={classes.root}>
      <div id="feedback-message"></div>
      <div className="md:hidden mb-12 flex flex-col items-center">
        <button className="rounded-md px-2 border-solid border-blue-500 justify-center border text-blue-500">
          <Trans i18nKey="common:headings.visits_mobile">
            ( 3 / 5 ) Visits
          </Trans>
        </button>
      </div>
      {toggleError && (
        <FeedbackMessage
          error
          heading={
            <Trans i18nKey="common:errors.error_message_heading"></Trans>
          }
          description={
            <Trans i18nKey="common:errors.error_message_description"></Trans>
          }
        />
      )}
      <p className="grey-heading mb-4">
        <Trans i18nKey="steps:visitStep.stepTopic"></Trans>
      </p>
      <h2 className="font-medium text-4xl text-gray-700 mb-4">
        <Trans i18nKey="steps:visitStep.stepGreeting"></Trans>
      </h2>

      <form className="mt-12 flex flex-col" onSubmit={handleSubmit} noValidate>
        <Grid xs={12} container>
          <Grid item xs={12}>
            <p className="blue-heading mt-8 mb-6">
              <Trans i18nKey="steps:visitStep.heading_therapy"></Trans>
            </p>
          </Grid>
          <Grid item xs={12}>
            <p className="mb-6 font-medium text-md text-gray-600">
              <Trans i18nKey="steps:visitStep.heading_choose_therapy"></Trans>
            </p>
          </Grid>
          <div className="flex flex-col w-full">
            <RadioGroup
              classes={{ root: classes.radioGroup }}
              className="w-full flex flex-row justify-between visit-checkboxes"
              aria-label="quiz"
              name="quiz"
              value={formState.therapyType}
            >
              <div className="">
                <div>
                  <FormControlLabel
                    className="mb-5 w-full"
                    value="fasting"
                    onChange={handleRadioChange}
                    control={
                      <RadioWithImage
                        color="primary"
                        imageUrl={image1Url}
                        label={t("steps:visitStep.pic1Label")}
                        ampliusFastLogo={ampliusFast}
                        linkText={t("steps:visitStep.pic1Link")}
                        href={t("steps:visitStep.pic1Href")}
                        alt="Amplius Med Logo"
                      />
                    }
                  />
                </div>
                <div>
                  <FormControlLabel
                    className="mb-8 w-full"
                    value="nutrition"
                    onChange={handleRadioChange}
                    control={
                      <RadioWithImage
                        color="primary"
                        imageUrl={image2Url}
                        label={t("steps:visitStep.pic2Label")}
                        ampliusFastLogo={ampliusCuisine}
                        linkText={t("steps:visitStep.pic2Link")}
                        href={t("steps:visitStep.pic2Href")}
                        alt="Amplius Med Logo"
                      />
                    }
                  />
                </div>
              </div>
            </RadioGroup>
          </div>
          <FeedbackMessage
            information
            heading={
              <Trans i18nKey="steps:visitStep.heading_information"></Trans>
            }
            description={
              <Trans i18nKey="steps:visitStep.picturesDescription"></Trans>
            }
          />
          <Grid xs={12}>
            <h2 className="blue-heading mt-8 mb-4">
              <Trans i18nKey="steps:visitStep.heading_travelling"></Trans>
            </h2>
          </Grid>
          <Grid container xs={12} spacing={1}>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={localeMap[i18n.language]}
              >
                <DatePicker
                  data-cy="arrival_date"
                  error={showErrors.arrivalDate ? true : false}
                  helperText={
                    showErrors.arrivalDate
                      ? t("common:errors.incorrect_entry")
                      : ""
                  }
                  inputVariant="outlined"
                  format="dd.MM.yyyy"
                  margin="normal"
                  id="outlined-required"
                  disablePast={true}
                  openTo="year"
                  fullWidth={true}
                  label={
                    <Trans i18nKey="steps:visitStep.label_arrival"></Trans>
                  }
                  value={formState.arrivalDate}
                  onChange={(date) =>
                    setFormState({ ...formState, arrivalDate: date })
                  }
                  onBlur={() => {
                    saveToCookie(formState, stepState.step);
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={localeMap[i18n.language]}
              >
                <DatePicker
                  data-cy="departure_date"
                  error={showErrors.departureDate ? true : false}
                  helperText={
                    showErrors.departureDate
                      ? t("common:errors.incorrect_entry")
                      : ""
                  }
                  inputVariant="outlined"
                  format="dd.MM.yyyy"
                  margin="normal"
                  id="outlined-required"
                  disablePast={true}
                  openTo="year"
                  fullWidth={true}
                  label={
                    <Trans i18nKey="steps:visitStep.label_departure"></Trans>
                  }
                  minDate={formState.arrivalDate}
                  value={formState.departureDate}
                  onChange={(date) =>
                    setFormState({ ...formState, departureDate: date })
                  }
                  onBlur={() => {
                    saveToCookie(formState, stepState.step);
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Grid xs={12}>
            <p className="font-medium my-8 text-md mb-4 text-gray-700">
              <Trans i18nKey="steps:visitStep.text_travelling">
                <sup>1</sup>
              </Trans>
            </p>
          </Grid>

          <Grid xs={12}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              id="outlined-multiline-static"
              label={
                <Trans i18nKey="steps:visitStep.text_travelling_label"></Trans>
              }
              placeholder={t("steps:visitStep.text_travelling_placeholder")}
              value={formState.expectedTimeOfArrival}
              onChange={(event) =>
                setFormState({
                  ...formState,
                  expectedTimeOfArrival: event.target.value,
                })
              }
              onBlur={() => {
                saveToCookie(formState, stepState.step);
              }}
              multiline
              rows={7}
              fullWidth={true}
              defaultValue="Default Value"
              variant="outlined"
            />
          </Grid>

          <Grid xs={12}>
            <p className="font-medium my-8 text-md mb-4 text-gray-700">
              <Trans i18nKey="steps:visitStep.text_travelling_car"></Trans>
            </p>
          </Grid>

          <Grid item sm={4} xs={12} spacing={3}>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={formState.travellingByCar}
              defaultChecked={formState.travellingByCar}
              onChange={() =>
                setFormState({

                  ...formState, travellingByCar: !formState.travellingByCar
                })
              }
              onBlur={() => {
                saveToCookie(formState, stepState.step);
              }}
            >
              <div>
                <FormControlLabel
                  data-cy="travelling_by_car"
                  value={false}
                  control={<Radio color="primary" />}
                  label={<Trans i18nKey="steps:visitStep.label_no"></Trans>}
                />
              </div>
              <div>
                <FormControlLabel
                  value={true}
                  control={<Radio color="primary" />}
                  label={<Trans i18nKey="steps:visitStep.label_yes"></Trans>}
                />
              </div>
            </RadioGroup>
          </Grid>

          <Grid xs={12}>
            <p className="font-medium my-6 text-md mb-4 text-gray-700">
              <Trans i18nKey="steps:visitStep.text_travelling_shuttle"></Trans>
            </p>
          </Grid>

          <Grid item sm={4} xs={12} spacing={3}>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={formState.shuttleRequested}
              defaultChecked={formState.shuttleRequested}
              onChange={(event) =>
                setFormState({
                  ...formState,
                  shuttleRequested:!formState.shuttleRequested
                })
              }
              onBlur={() => {
                saveToCookie(formState, stepState.step);
              }}
            >
              <div>
                <FormControlLabel
                  value={false}
                  control={<Radio color="primary" />}
                  label={<Trans i18nKey="steps:visitStep.label_no"></Trans>}
                />
              </div>
              <div>
                <FormControlLabel
                  value={true}
                  control={<Radio color="primary" />}
                  label={<Trans i18nKey="steps:visitStep.label_yes"></Trans>}
                />
              </div>
            </RadioGroup>
          </Grid>

          {formState.shuttleRequested && (
            <Grid xs={12}>
              <p className="font-medium text-md text-gray-600">
                <Trans i18nKey="steps:visitStep.text_travelling_pickup"></Trans>
              </p>
            </Grid>
          )}
          {/* WEITER LEISTUNG */}
          <Grid xs={12}>
            <h2 className="blue-heading mt-8 mb-4">
              <Trans i18nKey="steps:visitStep.heading_further_services"></Trans>
            </h2>
          </Grid>
          <Grid xs={12}>
            <p className="font-medium my-3 text-md mb-4 text-gray-600">
              <Trans i18nKey="steps:visitStep.text_further_services"></Trans>
            </p>
          </Grid>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formState.mediaTV}
                  onChange={(event) =>
                    setFormState({ ...formState, mediaTV: !formState.mediaTV })
                  }
                  onBlur={() => {
                    saveToCookie(formState, stepState.step);
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label={<Trans i18nKey="steps:visitStep.label_tv"></Trans>}
            />
          </div>
          {/* <div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formState.mediaDVD}
                                    onChange={event => setFormState({...formState, mediaDVD: !formState.mediaDVD})}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label={<Trans i18nKey='steps:visitStep.label_dvd'></Trans>}
                        />
                    </div> */}
          <div className="my-6 p-4 information-box">
            <h2 className="blue-heading mb-2">
              <InfoOutlinedIcon className="text-blue-500" />{" "}
              <span className="pl-1 text-blue-500">
                <Trans i18nKey="steps:visitStep.heading_information"></Trans>
              </span>
            </h2>
            <p className="ml-9">
              <Trans i18nKey="steps:visitStep.text_info"></Trans>
            </p>
          </div>
          <Grid container xs={12}>
            <h2 className="blue-heading mt-8 mb-6">
              <Trans i18nKey="steps:visitStep.heading_own_contribution"></Trans>
            </h2>
          </Grid>
          <Grid container xs={12}>
            <p className="font-medium text-md mb-4 text-gray-600">
              <Trans i18nKey="steps:visitStep.text_own_contribution"></Trans>
            </p>
          </Grid>
          <Grid container xs={12} className="my-12">
            <Grid item xs={5}>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={formState.talk}
                defaultChecked={formState.talk}
                onChange={(event) =>
                  setFormState({
                    ...formState,talk:event.target.value,
                  })
                }
                onBlur={() => {
                  saveToCookie(formState, stepState.step);
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio color="primary" />}
                  label={<Trans i18nKey="steps:visitStep.label_yes"></Trans>}
                />
                <FormControlLabel
                  value="no"
                  control={<Radio color="primary" />}
                  label={<Trans i18nKey="steps:visitStep.label_no"></Trans>}
                />
                <FormControlLabel
                  value="maybe"
                  control={<Radio color="primary" />}
                  label={<Trans i18nKey="steps:visitStep.label_maybe"></Trans>}
                />
              </RadioGroup>
            </Grid>

            <Grid item xs={7}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                id="outlined-multiline-static"
                label={<Trans i18nKey="steps:visitStep.label_message"></Trans>}
                placeholder={t("steps:visitStep.placeholder_message")}
                value={formState.talkDetail}
                onChange={(event) =>
                  setFormState({ ...formState, talkDetail: event.target.value })
                }
                onBlur={() => {
                  saveToCookie(formState, stepState.step);
                }}
                multiline
                rows={7}
                fullWidth={true}
                defaultValue="Default Value"
                variant="outlined"
              />
            </Grid>
          </Grid>
          {!postState[0].firstTime && (
            <Grid container xs={12} className="my-12">
              <Grid item xs={12}>
                <h2 className="blue-heading mt-8 mb-4">
                  <Trans i18nKey="steps:visitStep.heading_therapy_planning"></Trans>
                </h2>
                <p className="font-medium text-md mb-4 text-gray-600">
                  <Trans i18nKey="steps:visitStep.text_therapy_planning"></Trans>
                </p>
              </Grid>
              {/* TODO */}
              {/* value yet to be decided */}
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={formState.therapyPlanning}
                onChange={(event) =>
                  setFormState({
                    ...formState,
                    therapyPlanning: JSON.parse(event.target.value),
                  })
                }
                onBlur={() => {
                  saveToCookie(formState, stepState.step);
                }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio color="primary" />}
                  label={<Trans i18nKey="steps:visitStep.label_yes"></Trans>}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio color="primary" />}
                  label={<Trans i18nKey="steps:visitStep.label_no"></Trans>}
                />
              </RadioGroup>
            </Grid>
          )}
          <Grid container xs={12} className="buttons_bar">
            {toggleError && (
              <Grid item xs={12}>
                <p className="font-medium text-md mb-4 text-gray-600 flex justify-end">
                  <Trans i18nKey="steps:personalInformation.validation_error_message"></Trans>
                </p>
              </Grid>
            )}
            <Grid item xs={12}>
              <p className="mt-2">
                <Trans i18nKey="steps:general.mandatory"></Trans>
              </p>
            </Grid>
            <Grid item xs={6} className="mt-6">
              <Button
                variant="contained"
                size="large"
                color="default"
                onClick={stepBackHandler}
              >
                <Trans i18nKey="steps:visitStep.button_previous"></Trans>
              </Button>
            </Grid>
            <Grid xs={6} className="mt-6" container justify="flex-end">
              <Button
                data-cy="submit"
                variant="contained"
                size="large"
                color="primary"
                type="submit"
                onClick={() => scrollToTopHandler()}
              >
                <Trans i18nKey="steps:visitStep.button_next"></Trans>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default VisitStep;
