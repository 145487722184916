import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import { MenuItem, TextField } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import { saveToCookie } from "../wizard/util/cookiesHelper";

const PlacesAutocomplete = ({
  setAddressInfo,
  addressInfo,
  countriesList,
  setCountryLocale,
  stepName
}) => {
  const { t } = useTranslation(["translation", "common"]);
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  function extractAddress(results) {
    const street = results.address_components.filter(
      (r) => r.types[0] === "route"
    )[0]?.long_name;
    const streetNumber = results.address_components.filter(
      (r) => r.types[0] === "street_number"
    )[0]?.long_name;
    const postalCode = results.address_components.filter(
      (r) => r.types[0] === "postal_code"
    )[0]?.long_name;
    const city = results.address_components.filter(
      (r) => r.types[0] === "locality"
    )[0]?.long_name;
    const countryLocale = results.address_components.filter(
      (r) => r.types[0] === "country"
    )[0]?.short_name;

    return { street, streetNumber, postalCode, city, countryLocale };
  }

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect =
    ({ description }) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();

      // Get API results via utility functions
      getGeocode({ address: description })
        .then((results) => extractAddress(results[0]))
        .then(({ street, streetNumber, postalCode, city, countryLocale }) => {
          setCountryLocale(countryLocale);
          const newAddress = {
            ...addressInfo,
            addressStreet: `${street === undefined || null ? "" : street} ${
              streetNumber === undefined || null ? "" : streetNumber
            }`,
            addressPLZ: `${postalCode ? postalCode : ""}`,
            addressResidency: `${city ? city : ""}`,
            addressCountry: countriesList[countryLocale],
          };
          setAddressInfo(newAddress);
          saveToCookie(newAddress, stepName)
        })
        .catch((error) => {
          console.log("😱 Error: ", error);
        });
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <div className="ml-4" key={suggestion.place_id}>
          <MenuItem
            data-cy="autocomplete-suggestion"
            key={place_id}
            onClick={handleSelect(suggestion)}
            value={main_text}
          >
            {main_text},{secondary_text}
          </MenuItem>
        </div>
      );
    });

  return (
    <div ref={ref}>
      <TextField
        data-cy="autocomplete"
        className="w-full ml-2"
        value={value}
        onChange={handleInput}
        disabled={!ready}
        label={
          <Trans i18nKey="steps:personalInformation.addressSearch"></Trans>
        }
        placeholder={t("steps:personalInformation.addressSearch")}
        variant="outlined"
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === "OK" && <ul>{renderSuggestions()}</ul>}
    </div>
  );
};
export default PlacesAutocomplete;
