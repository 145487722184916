import { Cookies } from "react-cookie";

export const saveToCookie = (value, stepName) => {
  if (!stepName) return;
  const cookies = new Cookies();
  let result = cookies.get("buchinger");

  if (!result) {
    result = [];
  }
  let stepToOverwrite = result.find((item) => item?.step === stepName);
  if (!stepToOverwrite) {
    result.push((stepToOverwrite = {}));
  }

  if (!value.step) {
    value = { ...value, step: stepName };
  }
  Object.assign(stepToOverwrite, value);

  cookies.set("buchinger", result);
};
