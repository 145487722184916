const service = (formData) => {
  return fetch("https://bwcontactformbackend.bw-clinics.com/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  });
};

export default service;
