import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core";
import { themeColors } from "../../theme";
import { useTranslation, Trans } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    color: themeColors.gray.dark1,
    marginRight: theme.spacing(2),
    textTransform: "none",
  },
  selected: {
    color: theme.palette.primary.main,
  },
}));

export default function RightMenu({ selectedLanguage, onLanguageChange }) {
  const { t } = useTranslation(["common"]);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const lang = selectedLanguage === undefined ? "en" : selectedLanguage;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (language) => {
    onLanguageChange(language);
    handleClose();
  };

  return (
    <>
      <Button
        data-cy="language_dropdown"
        className={classes.menuItem}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {t("common:labels.language")}
      </Button>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleLanguageChange("en")}>
          <span className={lang === "en-US" ? classes.selected : undefined}>
            <Trans i18nKey="common:labels.english">English</Trans>{" "}
          </span>
        </MenuItem>
        <MenuItem
          data-cy="german_language"
          onClick={() => handleLanguageChange("de")}
        >
          <span className={lang === "de-DE" ? classes.selected : undefined}>
            <Trans i18nKey="common:labels.german">German</Trans>{" "}
          </span>
        </MenuItem>
        <MenuItem onClick={() => handleLanguageChange("fr")}>
          <span className={lang === "fr-FR" ? classes.selected : undefined}>
            <Trans i18nKey="common:labels.french">French</Trans>{" "}
          </span>
        </MenuItem>
        <MenuItem
          data-cy="spanish_language"
          onClick={() => handleLanguageChange("es")}
        >
          <span className={lang === "es-ES" ? classes.selected : undefined}>
            <Trans i18nKey="common:labels.spanish">Spanish</Trans>{" "}
          </span>
        </MenuItem>
      </Menu>
    </>
  );
}
