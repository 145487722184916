import { useState } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import useConfig from "../../hooks";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";
import { themeColors } from "../../theme";
import { useTranslation, Trans } from "react-i18next";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { saveToCookie } from "../util/cookiesHelper";

const useStyles = makeStyles((theme) => ({
  radioGroup: {
    flexDirection: "row",
    margin: 0,
  },
  radioButton: {
    marginLeft: -theme.spacing(1),
  },
  submitButton: {
    outline: "none",
    marginTop: theme.spacing(3),
    backgroundColor: themeColors.gray.fade2,
    color: themeColors.gray.fade3,
    textTransform: "none",
    width: 320,
    height: 50,
  },
  submitButtonActive: {
    outline: "none",
    marginTop: theme.spacing(3),
    backgroundColor: themeColors.blue.dark1,
    color: themeColors.blue.fade1,
    textTransform: "none",
    width: 320,
    height: 50,

    "&:hover": {
      backgroundColor: themeColors.blue.dark1,
    },
  },
}));

const RadioWithImage = (props) => {
  const { imageUrl, label, ...rest } = props;

  const classes = useStyles();

  return (
    <div className="w-full">
      <img
        className="w-full h-44 object-cover rounded-md"
        alt=""
        src={props.imageUrl}
      />

      <div className="mt-3">
        <Radio
          color="primary"
          classes={{ root: classes.radioButton }}
          {...rest}
        />
        <span className="text-gray-600">{label}</span>
      </div>
    </div>
  );
};

function WelcomeStep({ stepState, onSubmit }) {
  const { t } = useTranslation(["common", "steps"]);
  const [formState, setFormState] = useState(stepState);
  const [validateForm, setValidateForm] = useState(false);
  const imagesUrl = useConfig().imagesUrl;

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formState.firstTime === null) {
      setValidateForm(true);
      return;
    }
    saveToCookie(formState, stepState.step);
    onSubmit(formState, 0);
  };

  const handleRadioChange = (event) => {
    setFormState((state) => ({
      ...state,
      firstTime: JSON.parse(event.target.value),
    }));
    setValidateForm(false);
  };

  const image1Url = `${imagesUrl}step1-1-1.png`;
  const image2Url = `${imagesUrl}step1-1-2.png`;

  const classes = useStyles();

  return (
    <div>
      <div className="md:hidden mb-12 flex flex-col items-center">
        <button className="rounded-md px-2 border-solid border-blue-500 justify-center border text-blue-500">
          <Trans i18nKey="common:headings.welcome_mobile">
            ( 1 / 5 ) Welcome
          </Trans>
        </button>
      </div>
      <p className="grey-heading mb-4">
        <Trans i18nKey="steps:welcome.stepTopic">Herzlich Wilkommen</Trans>
      </p>
      <p className="font-medium text-4xl text-gray-700 mb-4">
        <Trans i18nKey="steps:welcome.stepGreeting">Lieber Gast</Trans>
      </p>
      <p className="font-medium text-lg text-gray-500">
        <Trans i18nKey="steps:welcome.stepDescription" />
      </p>

      {validateForm && (
        <div className="my-6 p-4 border border-red-100 bg-red-100 rounded">
          <h2 className="text-black text-lg">
            <InfoOutlinedIcon className="text-red-500" />{" "}
            <span className="pl-1 text-red-500">
              <Trans i18nKey="steps:welcome.error"></Trans>
            </span>
          </h2>
        </div>
      )}

      <form className="mt-12 flex flex-col" onSubmit={handleSubmit} noValidate>
        <div className="flex justify-between">
          <RadioGroup
            classes={{ root: classes.radioGroup }}
            className="w-full flex flex-row justify-between"
            aria-label="quiz"
            name="quiz"
            value={formState.firstTime}
          >
            <Grid container xs={12} justify="space-between">
              <Grid sm={6} xs={12} className="mb-6">
                <FormControlLabel
                  data-cy="first_timer"
                  value={true}
                  onChange={handleRadioChange}
                  control={
                    <RadioWithImage
                      imageUrl={image1Url}
                      label={t("steps:welcome.firstTime")}
                    />
                  }
                />
              </Grid>
              <Grid sm={6} xs={12}>
                <FormControlLabel
                  data-cy="regular_visitor"
                  value={false}
                  onChange={handleRadioChange}
                  control={
                    <RadioWithImage
                      imageUrl={image2Url}
                      label={t("steps:welcome.alreadyVisited")}
                    />
                  }
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </div>

        <div className="mt-12 flex flex-col items-center">
          <Button
            data-cy="submit"
            type="submit"
            classes={
              formState.firstTime !== null
                ? { root: classes.submitButtonActive }
                : { root: classes.submitButton }
            }
          >
            {t("common:labels.fillRegistrationForm")}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default WelcomeStep;
