import React from "react";
import { Trans } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { FeedbackMessage } from "../../components/FeedbackMessage";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

function SuccessStep({ stepState, onSubmit }) {
  const classes = useStyles();
  const handleSubmit = (event) => {
    event.preventDefault();
  };
  return (
    <div className={classes.root}>
      <form action="#" onSubmit={handleSubmit} noValidate>
        <p className="blue-heading mt-8 mb-6">
          <Trans i18nKey="steps:successStep.stepTopic"></Trans>
        </p>
        <p className="font-medium text-4xl text-gray-700 mb-4">
          <Trans i18nKey="steps:successStep.stepGreeting" />
        </p>
        <FeedbackMessage
        information
        heading={
          <Trans i18nKey="steps:successStep.heading_information" />
        }
        description={
          <Trans i18nKey="steps:successStep.text_information" />
        }
        />
      </form>
    </div>
  );
}

export default SuccessStep;
