import "react-app-polyfill/ie11";
import { CookiesProvider } from "react-cookie";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import "./i18n";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./index.css";

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="loading">
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
