import React from 'react';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

export const FeedbackMessage = ({children, information, error, heading, description, detail}) => {
    return (
      <div className={`my-6 p-4 information-box${error ? '--error' : ''}${detail ? '--detail' : ''}`}>
        <h2 className="blue-heading mb-2">
          {!detail &&
            <InfoOutlinedIcon className={`text-${error ? 'red-500' : '' }${information ? 'blue-500' : ''}`} />
          }
          <span className= {`pl-1 text-${error ? 'red-500' : '' }${information ? 'blue-500' : ''}${detail ? 'detail__text -ml-1' : ''}`}>
            {heading}
          </span>
        </h2>
        <p className={`${error ? 'text-red-500' : '' } ${detail ? 'ml-0 mt-6' : 'ml-9'}`}>
          {description}
        </p>
        {children}
      </div>
    )
}
